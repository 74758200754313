import React from "react";
import { Box } from "@src/components/Boxes";
import BlogArticle from "@src/components/blog/BlogArticle";
import Container from "@src/components/Container";
import NextPrevNav from "@src/components/blog/NextPrevNav";
import BlogSEO from "@src/components/blog/BlogSEO";
import { graphql, useStaticQuery } from "gatsby";

const BlogPost = ({ pageContext }) => {
  const post = pageContext.post;
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          devBlogUrl
          blogUrl
        }
      }
    }
  `);

  const { site } = data;

  let blogUrl;
  if (process.env.GATSBY_BUILD_WEBSITE === "blog") {
    blogUrl = site.siteMetadata.blogUrl;
  } else {
    blogUrl = site.siteMetadata.devBlogUrl;
  }

  return (
    <Box backgroundColor="#f5f7f9" overflow="auto">
      <BlogSEO
        title={post.title}
        description={post.body.indexOf("\n") > 10 ? post.body.substring(0, post.body.indexOf("\n")) : post.body}
        image={post.image && post.image.file ? blogUrl + post.image.file.publicURL : null}
        type="article"
        publishDate={post.publish_date_unformatted}
        slug={post.path}
        canonicalUrl={pageContext.post.canonical_url}
      />
      <Box backgroundColor="#f5f7f9" paddingTop={["90px", "95px"]} paddingBottom="95px">
        <Container>
          <Box width={["100%", "100%", 10 / 12, 8 / 12]} mx="auto" px={[0, "15px"]}>
            <BlogArticle post={post} />
            <NextPrevNav
              nextLink={pageContext.next}
              prevLink={pageContext.previous}
              nextText="Previous Post"
              prevText="Next Post"
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default BlogPost;
